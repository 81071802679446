/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Debian 12, también conocido como Bookworm ya es la nueva versión testing de la\nclásica distribución Debian. Por desgracia Nvidia no da soporte a versiones testing\npara su Container Toolkit."), "\n", React.createElement(_components.p, null, "Nvidia Container Toolkit es la librería que proporciona el fabricante de GPUs para\npoder usar la tarjeta gráfica dentro de contenedores Docker."), "\n", React.createElement(_components.p, null, "La solución que he encontrado, aunque no aseguro que vaya a funcionar al 100%, es\nutilizar el paquete de Debian 11, la forma de instalarlo es la siguiente:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token assign-left variable\">distribution</span><span class=\"token operator\">=</span><span class=\"token variable\"><span class=\"token variable\">$(</span><span class=\"token builtin class-name\">echo</span> debian11<span class=\"token variable\">)</span></span> <span class=\"token punctuation\">\\</span>\n   <span class=\"token operator\">&amp;&amp;</span> <span class=\"token function\">curl</span> <span class=\"token parameter variable\">-s</span> <span class=\"token parameter variable\">-L</span> https://nvidia.github.io/nvidia-docker/gpgkey <span class=\"token operator\">|</span> <span class=\"token function\">sudo</span> apt-key <span class=\"token function\">add</span> - <span class=\"token punctuation\">\\</span>\n   <span class=\"token operator\">&amp;&amp;</span> <span class=\"token function\">curl</span> <span class=\"token parameter variable\">-s</span> <span class=\"token parameter variable\">-L</span> https://nvidia.github.io/nvidia-docker/<span class=\"token variable\">$distribution</span>/nvidia-docker.list <span class=\"token operator\">|</span> <span class=\"token function\">sudo</span> <span class=\"token function\">tee</span> /etc/apt/sources.list.d/nvidia-docker.list\n<span class=\"token function\">sudo</span> <span class=\"token function\">apt-get</span> update\n<span class=\"token function\">sudo</span> <span class=\"token function\">apt-get</span> <span class=\"token function\">install</span> <span class=\"token parameter variable\">-y</span> nvidia-container-toolkit</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "Una vez hemos instalado el toolkit podemos comprobar que los contenedores tienen\nacceso a las gráficas con el siguiente comando:"), "\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<div class=\"gatsby-highlight\" data-language=\"bash\"><pre class=\"language-bash\"><code class=\"language-bash\"><span class=\"token function\">sudo</span> <span class=\"token function\">docker</span> run <span class=\"token parameter variable\">--rm</span> <span class=\"token parameter variable\">--runtime</span><span class=\"token operator\">=</span>nvidia <span class=\"token parameter variable\">--gpus</span> all nvidia/cuda:11.6.2-base-ubuntu20.04 nvidia-smi</code></pre></div>"
    }
  }), "\n", React.createElement(_components.p, null, "La salida de este comando debería ser parecida a la que obtendríamos ejecutando\n", React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<code class=\"language-text\">nvidia-smi</code>"
    }
  }), " en el host. Para cualquier duda podéis consultar ", React.createElement(_components.a, {
    href: "https://docs.nvidia.com/datacenter/cloud-native/container-toolkit/install-guide.html"
  }, "la guía oficial"), "."), "\n", React.createElement(_components.p, null, "Por ahora me ha funcionado correctamente para ejecutar Stable Diffusion desde un contenedor, para muestra la imagen de este post."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
